import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AppService {

  loggedIn = new Subject<boolean>();
  homePage = new Subject<boolean>();
  changed = new Subject<boolean>();
  constructor() { }

  isLoggedIn(flag: boolean) {
    this.loggedIn.next(flag);
  }
  isHomePage(flag: boolean) {
    this.homePage.next(flag);
  }
  isChanged(flag: boolean) {
    this.changed.next(flag); 
  }

}
