import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DatasaveService } from '../shared/service/datasave.service';
import { CustomNotifierService } from '../core/notifier/notifier.service';

@Injectable({
  providedIn: 'root'
})
export class PreventAuthGuard implements CanActivate {
  constructor(private router: Router, private dataSaveService: DatasaveService,private notifier: CustomNotifierService) {
  }
  canActivate(): boolean {
    if (this.dataSaveService.getData('jwt') != null) {
      this.notifier.showNotification('success', 'redirecting to dashboard');
      this.router.navigateByUrl('/auth/login');
      return false;
    }
    else {
      return true;
    }
  }

}
