import { Injectable } from '@angular/core';

@Injectable()
export class DatasaveService {

  constructor() { }

  public saveData(key: string, item: any) {
    sessionStorage.setItem(key, JSON.stringify(item));
  }

  public getData(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  public getAllData(): any {
    return (sessionStorage);
  }

  public removeData(key: string) {
    sessionStorage.removeItem(key);
  }

  public removeAll() {
    sessionStorage.clear();
  }

}
