import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/http-client/http-client.service';
import { Observable } from 'rxjs';
import { responseModel } from 'src/app/core/model/response.model';
import { RequestOptions } from 'src/app/core/model/requestoptions.model';
import { HttpHeaders } from '@angular/common/http';
import { DatasaveService } from 'src/app/shared/service/datasave.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(
    private httpClient: HttpClientService,
    private requestOptions: RequestOptions,
    private dataSaveService: DatasaveService,
    public datepipe: DatePipe
  ) {
  }


  public getPaymentList(paymentDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/bus/get_all_payments', paymentDetails, { headers: reqHeader });
  }
  public getNotificationList(paymentDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/notifications/get_all_notifications', paymentDetails, { headers: reqHeader });
  }
  public getRefundAmount(amountDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/payment/get_refund_amount', amountDetails, { headers: reqHeader });
  }
  public updateRefundAmount(amountDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/payment/update_refund_amount', amountDetails, { headers: reqHeader });
  }
  public getInvoiceData(receiptDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.post('/bus/get_invoice_data', receiptDetails, { headers: reqHeader });
  }
  public cancelBooking(paymentDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/payment/refund_payment', paymentDetails, { headers: reqHeader });
  }
}
