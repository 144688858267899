import { Component, Input, enableProdMode } from '@angular/core';
import { DatasaveService } from './shared/service/datasave.service';
import { Subject } from 'rxjs';
import { AppService } from './core/app.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
enableProdMode();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Kadam Travels';
  loggedIn: Subject<boolean> = this.appService.loggedIn;
  homePage: Subject<boolean> = this.appService.homePage;
  preloader: boolean;
  constructor(private router: Router, private appService: AppService, private dataSaveService: DatasaveService, private spinner: NgxSpinnerService) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        let arr = [
          '/auth/privacy-policy',
          '/auth/terms-and-conditions',
          '/webview/about-us',
          '/webview/refer-to-earn',
          '/webview/help',
          '/webview/call-support',
          '/webview/generate-invoice'
        ];
        if (arr.indexOf(event.url) > -1) {
        } else {
          this.spinner.show();
        }
        this.preloader = true;

        if (event.url == '/') {
          this.appService.isHomePage(true);
        } else {
          this.appService.isHomePage(false);
        }
        if (this.dataSaveService.getData("jwt") != null) {
          this.appService.isLoggedIn(true);
        } else {
          this.appService.isLoggedIn(false);
        }
      }
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
        this.appService.isChanged(true);
        this.preloader = false;
      }
    });

  }
  ngOnInit() {
  }
}
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/markdown/markdown';
import { NgxSpinnerService } from 'ngx-spinner';
