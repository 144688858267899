import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DatasaveService } from '../shared/service/datasave.service';
import { CustomNotifierService } from '../core/notifier/notifier.service';

@Injectable({
  providedIn: 'root'
})
export class PreventRoutesGuard implements CanActivate {
  constructor(private router: Router, private dataSaveService: DatasaveService,private notifier: CustomNotifierService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (next.routeConfig.path == 'register') {
      return true;
    }
    if ((this.dataSaveService.getData("roleId") != '4') && next.routeConfig.path == 'admin') {
      return true;
    }
    else {
      this.notifier.showNotification('error', 'You don\'t have access to this page');
      this.router.navigate(['/auth/login']);
      return false;
    }
  }

}
