import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from '../core/default/default.component';
import { AuthGuard } from '../guards/auth.guard';
import { PreventRoutesGuard } from '../guards/prevent-routes.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', component: DefaultComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'auth', loadChildren: () => import('../modules/authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'admin', loadChildren: () => import('../modules/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard, PreventRoutesGuard] },
  { path: 'webview', loadChildren: () => import('../modules/webview/webview.module').then(m => m.WebviewModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
