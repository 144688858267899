import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '../model/requestoptions.model';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';

export function appHttpClientCreator(http: HttpClient, requestOptions: RequestOptions, configService: ConfigService) {
  return new HttpClientService(http, requestOptions, configService);
};

@Injectable()
export class HttpClientService {
  parentUrl: string;
  chennaiUrl: string;

  constructor(private http: HttpClient, private requestOptions: RequestOptions, private configService: ConfigService) {
    this.requestOptions.headers = new HttpHeaders();
    this.requestOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.requestOptions.headers.append('Content-Type', 'application/json');
    this.parentUrl = this.configService.getApiUrl();
  }

  public get<T>(apiURL: string, options?: RequestOptions): Observable<T> {
    return this.http.get<T>(this.parentUrl + apiURL, options === undefined ? this.requestOptions : options);
  }
  public getLocal<T>(apiURL: string, params: Object | Array<T>, options?: RequestOptions): Observable<T> {
    return this.http.post<T>(apiURL, params, options === undefined ? this.requestOptions : options);
  }

  public post<T>(apiURL: string, params: Object | Array<T>, options?: RequestOptions): Observable<T> {
    return this.http.post<T>(this.parentUrl + apiURL, params, options === undefined ? this.requestOptions : options);
  }

  public put<T>(apiURL: string, params: Object, options?: RequestOptions): Observable<T> {
    return this.http.put<T>(this.parentUrl + apiURL, params, options === undefined ? this.requestOptions : options);
  }

  public delete<T>(apiURL: string, options?: RequestOptions): Observable<T> {
    return this.http.delete<T>(this.parentUrl + apiURL, options === undefined ? this.requestOptions : options);
  }

}
