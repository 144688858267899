import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { Subject } from 'rxjs';
import { DatasaveService } from 'src/app/shared/service/datasave.service';
declare var $;
@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: []
})
export class DefaultComponent implements OnInit {
  loggedIn: boolean = false;
  constructor(private router: Router, private appService: AppService, private dataSaveService: DatasaveService) {
    this.router.navigate(['auth/login']);
  }

  ngOnInit() {
    this.appService.isHomePage(true);
    if (this.dataSaveService.getData("jwt") != null) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  }
}
