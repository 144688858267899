import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/http-client/http-client.service';
import { Observable } from 'rxjs';
import { responseModel } from 'src/app/core/model/response.model';
import { RequestOptions } from 'src/app/core/model/requestoptions.model';
import { HttpHeaders } from '@angular/common/http';
import { DatasaveService } from 'src/app/shared/service/datasave.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class CompanyService {

  constructor(private httpClient: HttpClientService, private requestOptions: RequestOptions, private dataSaveService: DatasaveService, public datepipe: DatePipe) {
  }

  //TeamMember
  public saveTeamMembers(teamMemberDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/requestteam', teamMemberDetails, { headers: reqHeader });
  }

  public getTeamMembers(teamMemberDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/getteam', this.dataSaveService.getData("email"), { headers: reqHeader });
  }

  public deleteTeamMembers(teamMemberDetails): Observable<responseModel> {
    let param = `{
    "apiType": "1",
      "columnName": "string",
        "columnValue": "string",
          "tableName": "tbl_user",
            "updateColumnData": [
              {
                "updateColumnName": "user_id",
                "updateColumnValue": `+ teamMemberDetails.userId + ` 
              }
            ],
              "updateColumnName": "string",
                "updateColumnValue": "string"
  } `;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/deleteorisactive', param, { headers: reqHeader });
  }

  public updateTeamMemberRole(teamMemberDetails, userRole): Observable<responseModel> {
    let param = `{
      "apiType": "3",
      "columnName": "user_id",
      "columnValue": "`+ teamMemberDetails.userId + `",
      "tableName": "tbl_user",
      "updateColumnData": [
        {
          "updateColumnName": "user_role",
          "updateColumnValue": `+ userRole + `
        }
      ],
      "updateColumnName": "string",
      "updateColumnValue": "string"
    }`;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/deleteorisactive', param, { headers: reqHeader });
  }
  public deactiateTeamMember(teamMemberDetails): Observable<responseModel> {
    let param = `{
      "apiType": "3",
      "columnName": "user_id",
      "columnValue": "`+ teamMemberDetails.userId + `",
      "tableName": "tbl_user",
      "updateColumnData": [
        {
          "updateColumnName": "user_active",
          "updateColumnValue": "0"
        },
        {
          "updateColumnName": "deleted_by_id",
          "updateColumnValue": `+ this.dataSaveService.getData('userId') + `
        }
        ,
        {
          "updateColumnName": "deleted_by_date",
          "updateColumnValue": "`+ this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss') + `"
        }
      ],
      "updateColumnName": "string",
      "updateColumnValue": "string"
    }`;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/deleteorisactive', param, { headers: reqHeader });
  }
  public getCount(teamMemberDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/deleteorisactive', teamMemberDetails, { headers: reqHeader });
  }

  public getUserLimit(userLimitDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/teamlimitchecker', userLimitDetails, { headers: reqHeader });
  }

  //Candidates
  public saveCandidates(teamMemberDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/addcandidaterequest', teamMemberDetails, { headers: reqHeader });
  }

  public deleteCandidates(teamMemberDetails): Observable<responseModel> {
    let param = '{ "apiType": "1", "tableName": "tbl_user","columnName": "user_id",  "columnValue": ' + teamMemberDetails.userId + ' } ';
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/deleteorisactive', param, { headers: reqHeader });
  }
  public getMyCandidate(paginationDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/mycandidate', paginationDetails, { headers: reqHeader });
  }
  public getBusinessContactList(paginationDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/getbusinesscontactlist', paginationDetails, { headers: reqHeader });
  }
  public getMyAssignment(paginationDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/myassignment', paginationDetails, { headers: reqHeader });
  }
  public saveAssignments(assignmentDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/saveassignment', assignmentDetails, { headers: reqHeader });
  }
  public deleteAssignments(assignmentDetails): Observable<responseModel> {
    let param = `{
    "apiType": "1",
      "columnName": "string",
        "columnValue": "string",
          "tableName": "tbl_assignment",
            "updateColumnData": [
              {
                "updateColumnName": "assignment_id",
                "updateColumnValue": `+ assignmentDetails.assignmentId + ` 
              }
            ],
              "updateColumnName": "string",
                "updateColumnValue": "string"
  } `;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/deleteorisactive', param, { headers: reqHeader });
  }
  public deleteAssignmentSkills(assignmentSkillsId): Observable<responseModel> {
    let param = `{
    "apiType": "1",
      "columnName": "string",
        "columnValue": "string",
          "tableName": "tbl_assignment_skills",
            "updateColumnData": [
              {
                "updateColumnName": "assignment_skills_id",
                "updateColumnValue": `+ assignmentSkillsId + ` 
              }
            ],
              "updateColumnName": "string",
                "updateColumnValue": "string"
  } `;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/deleteorisactive', param, { headers: reqHeader });
  }
  public getCompany(companyDetails): Observable<responseModel> {

    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.get('/company/getcompany/' + companyDetails, { headers: reqHeader });
  }
  public getAllJobOpeningRequest(paginationDetails): Observable<responseModel> {

    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/common/getalljobopeningrequest' , paginationDetails, { headers: reqHeader });
  }

  public saveCompany(companyDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/company/savecompany', companyDetails, { headers: reqHeader });
  }


}