import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumbersOnlyDirective } from './directive/numbers-only.directive';
import { PriceOnlyDirective } from './directive/price-only.directive';

@NgModule({
  // declarations: [DialogComponent, NumbersOnlyDirective, AddAssignmentComponent, ListAssignmentComponent, ListAptitudeTestComponent, CreateAptitudeTestComponent, ListCandidateComponent],
  declarations: [NumbersOnlyDirective, PriceOnlyDirective],
  imports: [
    CommonModule,
  ]
})
export class SharedModule { }
