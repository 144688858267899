import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DefaultComponent } from './default/default.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AppService } from './app.service';
import { AppBreadcrumbModule, AppHeaderModule, AppFooterModule, AppSidebarModule } from '@coreui/angular';
import { CoreRoutingModule } from './core-routing.module';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsDropdownModule, BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { NumbersOnlyDirective } from '../shared/directive/numbers-only.directive';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { HttpClientService } from './http-client/http-client.service';
import { RequestOptions } from './model/requestoptions.model';
import { ConfigService } from './config/config.service';
import { CompanyService } from '../shared/service/company.service';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    DefaultComponent,
    SidebarComponent,
    PageNotFoundComponent,
    TermsAndConditionComponent,

  ],
  imports: [
    SharedModule,
    CommonModule,
    HttpClientModule,
    NgxPageScrollCoreModule.forRoot({ duration: 700 }),
    NgxPageScrollModule,
    CommonModule,
    AppBreadcrumbModule.forRoot(),
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
    CoreRoutingModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    DigitOnlyModule
  ],
  exports: [HeaderComponent,
    FooterComponent,
    DefaultComponent,
    SidebarComponent],
  providers: [AppService, BsModalService, BsDropdownDirective, CompanyService, HttpClientService, RequestOptions, ConfigService, DatePipe],
})
export class CoreModule { }
