import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { CustomNotifierService } from './core/notifier/notifier.service';
import { AuthenticationService } from './modules/authentication/auth.service';
import { DatasaveService } from './shared/service/datasave.service';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './guards/auth.guard';
import { PreventAuthGuard } from './guards/prevent-auth.guard';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ReferToEarnComponent } from './modules/webview/refer-to-earn/refer-to-earn.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
const notifierDefaultOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: "right",
      distance: 12
    },
    vertical: {
      position: "top",
      distance: 100,
      gap: 5
    }
  },
  theme: "material",
  behaviour: {
    autoHide: 10000,
    onClick: false,
    onMouseover: "pauseAutoHide",
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: "slide",
      speed: 300,
      easing: "ease"
    },
    hide: {
      preset: "fade",
      speed: 300,
      easing: "ease",
      offset: 50
    },
    shift: {
      speed: 300,
      easing: "ease"
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    AccordionModule.forRoot(),
    CoreModule,
    NotifierModule.withConfig(notifierDefaultOptions),
    BrowserAnimationsModule,
    NgxSpinnerModule
  ],
  providers: [CustomNotifierService, AuthenticationService, DatasaveService, AuthGuard, PreventAuthGuard],
  bootstrap: [AppComponent],
})

export class AppModule { }
