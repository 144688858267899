import { Component, OnInit } from '@angular/core';
import { navItemsAdmin } from './nav';
import { DatasaveService } from 'src/app/shared/service/datasave.service';
import { INavData } from '@coreui/angular';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  //variable
  sidebarMinimized: boolean;
  public sideBarNav: INavData[];
  constructor(private dataSaveService: DatasaveService) { }

  ngOnInit(): void {
    this.sideBarNav = this.dataSaveService.getData("listMenu");
  }
}
