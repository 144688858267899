import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {

  // prodUrl: string = "https://kadam-travels-api.herokuapp.com";
  prodUrl: string = "https://raigadshree-api.herokuapp.com";
  uatUrl: string = "https://raigadshree-uat-api.herokuapp.com";
  localUrl: string = "http://localhost:3000";
   testUrl: string = "https://api.rgstech.in";
  //  testUrl: string = "http://103.131.196.122:4201";
  vpsProdUrl: string = "https://api.urlo.in:3000";
  // vpsUatUrl: string = "https://raigadshree-api.urlo.in";
  vpsUatUrl: string = "https://api.jmeet.in";
  constructor() { }

  getApiUrl() {
     return this.testUrl;
    // return this.localUrl;
    // return this.vpsProdUrl;
    // return this.vpsUatUrl;
    // return this.uatUrl;
  }

}
