import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[priceOnly]'
})
export class PriceOnlyDirective {
 // Allow decimal numbers and negative values
 private regex: RegExp = new RegExp(/^-?\d*(\.\d+)?$/g);
 // Allow key codes for special events. Reflect :
 // Backspace, tab, end, home
 private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', '-' ];

constructor(private el: ElementRef) {
 }
 @HostListener('keydown', [ '$event' ])
 onKeyDown(event: KeyboardEvent) {
    return ( event.ctrlKey || event.altKey 
        || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
        || (95<event.keyCode && event.keyCode<106)
        || (event.keyCode==8) || (event.keyCode==9) 
        || (event.keyCode>34 && event.keyCode<40) 
        || (event.keyCode==46) )
 }
 
}