import { Component, OnInit, TemplateRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { DatasaveService } from 'src/app/shared/service/datasave.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/shared/service/company.service';
import { pagination } from 'src/app/shared/model/pagination.model';
import { PaymentService } from 'src/app/shared/service/payment.service';
import { CustomNotifierService } from '../notifier/notifier.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'appheader',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  loggedIn: Subject<boolean> = this.appService.loggedIn;
  homePage: Subject<boolean> = this.appService.homePage;
  subscription: Subscription;
  routes: [];
  totalJobOpeningRequests: number = 0;
  x: any;
  paginationDetails: pagination | any = <pagination>{};
  tempPayments: any = [];
  temp: any = new Set();
  noPaymentDataFound: boolean;
  currentPage: number = 0;
  refreshData: number;
  newData: number = 1;
  firstData: any;
  newCount: any;
  loading2: boolean = false;
  editedPayment: any;
  modalRef: any;
  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private dataSaveService: DatasaveService,
    private notifier: CustomNotifierService,
    private paymentService: PaymentService,
    private modalService: BsModalService
  ) {
    this.setPageData();

    this.x = setInterval(() => {
      console.log("refreshed again");
      this.refreshData = 1;
      this.setPageData();
    }, 50000);
  }
  logout() {
    this.dataSaveService.removeAll();
    this.router.navigate(['/']);
  }
  redirectToNewRequest() {
    if (this.dataSaveService.getData('roleId') == '10' || this.dataSaveService.getData('roleId') == '11' || this.dataSaveService.getData('roleId') == '12') {
      this.router.navigate(['/company/new-request'], { relativeTo: this.route });
    }
    if (this.dataSaveService.getData('roleId') == '13' || this.dataSaveService.getData('roleId') == '14' || this.dataSaveService.getData('roleId') == '15') {
      this.router.navigate(['/recruiter/new-request'], { relativeTo: this.route });
    }
  }
  getBreadcrum(route) {
    let routeArray = route.split('/');
    routeArray.splice('0', '1');
    for (let i = 0; i < routeArray.length; i++) {
      routeArray[i] = routeArray[i].replace(/-/g, ' ');
    }
    this.routes = routeArray;
  }
  ngOnInit() {
    this.appService.changed.subscribe(
      (route) => {
        this.getBreadcrum(window.location.href.replace(window.location.origin, ''));
      }
    );
  }
  routeToProfile() {
    if (this.dataSaveService.getData('roleId') == '10' || this.dataSaveService.getData('roleId') == '11' || this.dataSaveService.getData('roleId') == '12') {
      this.router.navigate(['/company/profile'], { relativeTo: this.route });
    }
    if (this.dataSaveService.getData('roleId') == '13' || this.dataSaveService.getData('roleId') == '14' || this.dataSaveService.getData('roleId') == '15') {
      this.router.navigate(['/recruiter/profile'], { relativeTo: this.route });
    }
  }
  setPageData() {
    // return;
    this.paginationDetails = {
      "pageNo": (this.refreshData == 1) ? 0 : this.currentPage,
      "pageSize": 5,
      "searchValue": "",
      "sortBy": "payment_id"
    };
    this.paymentService.getNotificationList(this.paginationDetails).subscribe(res => {
      this.loading2 = false;

      debugger;
      this.newCount = res.data.count;
      res.data.bookings.map((x: any, i) => {
        if (this.refreshData === 1) {
          const exist = this.tempPayments.filter(y => {
            if (y.ticket_number === x.ticket_number) {
              return y;
            }
          });
          console.log(exist);
          if (exist.length === 0) {
            console.log("inside this");
            this.notifier.showNotification("success", x.user_name + " booked " +
              x.name + " on " + x.travelling_date);
          }
        }
        if (x.payment_for != 2) {
          x.boarding_details = JSON.parse(x?.seats[0]?.boarding_details);
          x.dropping_details = JSON.parse(x?.seats[0]?.dropping_details);
        }
        this.temp.add(JSON.stringify(x));
      });
      console.log(this.newData);


      this.tempPayments = (Array.from(this.temp)).map((x: any) => {
        return JSON.parse(x);
      });
      console.log(this.tempPayments);
      this.refreshData = 0;
      this.newData = 0;

    }, err => {
      this.noPaymentDataFound = true;
    });
  }
  loadNext() {
    this.loading2 = true;
    this.currentPage = this.currentPage + 1;
    this.setPageData();
  }
  openNotificationModal(template: TemplateRef<any>, payment: any) {
    this.editedPayment = payment;
    this.modalRef = this.modalService.show(template);
  }

}
