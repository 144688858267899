import { Injectable } from '@angular/core';
import { NotifierService, NotifierOptions } from "angular-notifier";
@Injectable({
  providedIn: 'root'
})
export class CustomNotifierService {
	public notifierDefaultOptions: NotifierOptions = {
		position: {
			horizontal: {
				position: "left",
				distance: 12
			},
			vertical: {
				position: "bottom",
				distance: 12,
				gap: 10
			}
		},
		theme: "material",
		behaviour: {
			autoHide: 5000,
			onClick: false,
			onMouseover: "pauseAutoHide",
			showDismissButton: true,
			stacking: 4
		},
		animations: {
			enabled: true,
			show: {
				preset: "slide",
				speed: 300,
				easing: "ease"
			},
			hide: {
				preset: "fade",
				speed: 300,
				easing: "ease",
				offset: 50
			},
			shift: {
				speed: 300,
				easing: "ease"
			},
			overlap: 150
		}
	};
	private notifier: NotifierService;

	public constructor( notifier: NotifierService ) {
		this.notifier = notifier;
	}

	public showNotification( type: string, message: string ): void {
		this.notifier.notify( type, message );
	}

	public hideOldestNotification(): void {
		this.notifier.hideOldest();
	}

  public hideNewestNotification(): void {
		this.notifier.hideNewest();
	}

	public hideAllNotifications(): void {
		this.notifier.hideAll();
	}

	public showSpecificNotification( type: string, message: string, id: string ): void {
		this.notifier.show( {
			id,
			message,
			type
		} );
	}

	public hideSpecificNotification( id: string ): void {
		this.notifier.hide( id );
	}
}
