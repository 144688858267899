import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/core/http-client/http-client.service';
import { login } from './model/login.model';
import { Observable } from 'rxjs';
import { responseModel } from 'src/app/core/model/response.model';
import { DatasaveService } from 'src/app/shared/service/datasave.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthenticationService {

  constructor(private httpClient: HttpClientService, private dataSaveService: DatasaveService) {
  }
  public login(logindetails: login): Observable<responseModel> {
    return this.httpClient.post('/authentication/login', logindetails);
  }

  public resetPassword(setPasswordDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.post('/authentication/resetpassword', setPasswordDetails, { headers: reqHeader });
  }
  public changePassword(userDetails): Observable<responseModel> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.dataSaveService.getData("jwt")
    });
    return this.httpClient.post('/admin/change_password', userDetails, { headers: reqHeader });
  }

  loggedIn() {
    return !!this.dataSaveService.getData('jwt');
  }
}
